/* eslint-disable no-undef */
// import RevolutCheckout from '../prettier.config';
import './css/style.css';

const telega = window.Telegram.WebApp;
const colorScheme = telega.colorScheme;
const telegramInitData = window.Telegram.WebApp.initData;

const body = document.querySelectorAll('body');
const subject = document.getElementById('subject');
const message = document.getElementById('message');
const sendButton = document.getElementById('send-button');

if (colorScheme === 'dark') {
	document.body.classList.add('tg-dark');
}
console.log('colorScheme', colorScheme);
console.log('body', body);

console.log(telega);

subject.oninput = () => {
	if (subject.value.length > 1 && message.value.length > 1) {
		sendButton.disabled = false;
	} else sendButton.disabled = true;
};

message.oninput = () => {
	if (subject.value.length > 1 && message.value.length > 1) {
		sendButton.disabled = false;
	} else sendButton.disabled = true;
};

sendButton.onclick = () => {
	if (sendButton.disabled) return;
	fetch(`https://bot.atlanty.ru/api/telegram_users/send_request`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			telegramInitData: telegramInitData,
			subject: subject.value,
			message: message.value,
		}),
	});
};
